<template>
  <v-row>
    <v-col>
      <FilterTypes
      :activitiesSelected="activitiesSelectedParams"
      :bookingTypes="bookingTypes"
      :activities="getActivities"
      :selectedValue="selectedTypeValue"
      :dateDatePicker="selectedDatePickerValue"
      :first_day_of_week="day"
      :loadingProp="loading"
      @check-availability="checkAvailability"
      @selected-data="selectedData"
      @selected-date-picker="selectedDatePicker"
      @type-selected="selectedType"
      />
      <div class="calendar-container">
        <Calendar v-if="calendarEvents.length > 0"
        :colors="colors"
        :categoriesType="categoriesType"
        :intervalCount="intervalCount"
        :intervalMin="intervalMin"
        :events="calendarEvents"
        :valueDateCalendar="calendarEventDate"
        :event-text-color="textColor"
        :redirection="redirection"
        />
        <LoadingView v-else-if="loading == true"/>
        <EmptyEventsView v-else />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import FilterTypes from '../components/filter/Filter'
import Calendar from '../components/calendar/Calendar.vue'
import EmptyEventsView from './EmptyEventsView'
import LoadingView from './LoadingView'
import { GOGUEST_CONSOLE_BASE_URL } from '@/config/environment'
export default {
  name: 'points',
  components: {
    FilterTypes,
    Calendar,
    EmptyEventsView,
    LoadingView,
  },
  data () {
    return {
      selectedValue: [],
      availabilityChecked: [],
      defaultLanguage: process.env.VUE_APP_DEFAULT_LANGUAGE,
      filter: {},
      activitiesSelectedParams: [],
      loading: false,
      calendarEventDate: '',
      selectedTypeValue: {},
    }
  },
  computed: {
    getTokenValue () {
      return this.$store.state.state.token
    },
    getActivities () {
      return this.$store.state.activities.activitiesData
    },
    intervalMin () {
      return this.$store.state.activities.intervalMin
    },
    intervalCount () {
      return this.$store.state.activities.intervalCount
    },
    categoriesType () {
      return this.$store.state.activities.categoriesType
    },
    colors () {
      return this.$store.state.activities.colors
    },
    calendarEvents () {
      return this.$store.state.activities.calendarEvents
    },
    lockedEvent () {
      return this.$store.state.activities.lockedEvent
    },
    selectedTypeValueStore () {
      return this.$store.state.activities.type
    },
    bookingTypes () {
      return this.$store.state.activities.bookingTypes
    },
    selectedDatePickerValue () {
      return this.$store.state.activities.date
    },
    day () {
      return this.$store.state.activities.day
    },
    activitiesSelected () {
      return this.$store.state.activities.activitiesSelected
    },
  },
  methods: {
    redirection (event) {
      const locked = this.lockedEvent.find(item => item.lockedDate.getTime() == event.event.start.getTime() && item.activityId == event.event.activityId)
      if (locked)
        return
      const year = event.event.start.toLocaleString('default', {year: 'numeric'})
      const month = event.event.start.toLocaleString('default', {month: '2-digit'})
      const day = event.event.start.toLocaleString('default', {day: '2-digit'})
      const date = [year, month, day].join('-')
      const startMs = this.toMilliseconds(event.event.start.getHours(), event.event.start.getMinutes(), event.event.start.getSeconds())
      const URL = `${ GOGUEST_CONSOLE_BASE_URL }/reservations/activity/${ event.event.activityId }/activity-reservations/slot?date=${ date }&slot=${ startMs }&method=${ event.event.type }`
      const page = window.open(URL, '_blank', 'noreferrer')
      return page
    },
    textColor (event) {
      return event.textColor
    },
    async checkAvailability () {
      const translateResultx = this.translateResult
      const arrayValue = this.selectedDatePickerValue.split('-')
      this.filter.date = `${ arrayValue[2] }-${ arrayValue[1] }-${ arrayValue[0] }`
      this.filter.pageIds = this.selectedValue.length ? this.selectedValue.map(item => {return typeof(item) == 'object' ? item.pageId : item}).join(',') : ''
      this.filter.type = this.selectedTypeValue.value
      this.availabilityChecked = []
      for (let index = 0; index < this.selectedValue.length; index++) {
        if (this.availabilityChecked.includes(this.selectedValue[index])) {
          this.availabilityChecked.indexOf(this.selectedValue[index])
        }
        else {
          this.availabilityChecked.push(this.selectedValue[index])
        }
      }
      await this.executeCheckAvailability(this.filter)
    },
    async executeCheckAvailability (filter) {
      this.loading = true
      await this.$store.dispatch('activities/getActivitiesReservation', filter)
      this.loading = false
      this.calendarEventDate = this.selectedDatePickerValue
    },
    selectedData (params) {
      this.selectedValue = params
    },
    selectedDatePicker (params) {
      this.$store.commit('activities/SET_DATE', params)
    },
    selectedType (params) {
      this.selectedTypeValue = params
      this.$store.commit('activities/SET_TYPE', params)
    },
    toMilliseconds (hrs,min,sec) {
      return (hrs*60*60+min*60+sec)*1000
    },
    async getActivitiesFilter (getActivitiesSelected) {
      await this.$store.dispatch('activities/getActivities', getActivitiesSelected)
    },
    getFirstDayDatePicker () {
      this.$store.dispatch('activities/firstDayOfWeek')
    },
    getActivitiesSelected () {
      let activitiesSelectedFormat
      if(!this.activitiesSelected || !this.activitiesSelected.length)
        return
      for (let i = 0; i < this.activitiesSelected.length; i++) {
        for (let j = 0; j < this.getActivities.length; j++) {
          if (this.getActivities[j].value == this.activitiesSelected[i]) {
            activitiesSelectedFormat = {
              pageId: this.getActivities[j].pageId,
              name: this.getActivities[j].name,
              value: this.getActivities[j].value,
            }
          }
        }
        this.activitiesSelectedParams.push(activitiesSelectedFormat)
      }
    },
  },
  beforeMount () {
    this.selectedTypeValue = this.selectedTypeValueStore
  },
  async mounted () {
    await this.getActivitiesFilter(this.getActivitiesSelected)
    this.getFirstDayDatePicker()
    const filter = {}
    if (!this.$store.state.activities.date || !this.$store.state.activities.type.value)
      return
    const arrayValue = this.$store.state.activities.date.split('-')
    filter.date = `${ arrayValue[2] }-${ arrayValue[1] }-${ arrayValue[0] }`
    filter.pageIds = this.getActivities.length ? this.getActivities.filter(({ value }) => this.activitiesSelected.includes(value)).map(({ pageId }) => pageId).join(',') : ''
    filter.type = this.$store.state.activities.type.value
    this.executeCheckAvailability(filter)
  },
}
</script>
<style lang="scss">
.v-text-field.currency .v-text-field__slot>input {
  text-align: right;
  margin-right: 5px;
  font-size: 30px;
  max-height: 40px;
}

.alert__no-border {
  border-top: none !important;
}

.v-calendar-category {
    overflow: revert;
    position: relative;
}

.calendar-container {
  height: calc(100vh - 258px);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  min-width: 150px;
  min-height: 100px;
}
</style>
