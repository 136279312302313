<template>
    <v-container bg fill-height grid-list-md text-center class="mt-16">
         <v-layout wrap align-center>
           <v-flex justify-center class="mt-16">
             <div>
                 <v-icon color="yellow" size="6rem">error</v-icon>
                 <h1 class="text-h3 font-weight-medium pt-3 pb-5">{{ emptyMessage }}</h1>
             </div>
           </v-flex>
         </v-layout>
     </v-container>
 </template>
<script>
export default {
  name: 'emptyEvents',
  data () {
    return{
      emptyMessage: this.$t('emptyEventsMessage.emptyMessage'),
    }
  },
}
</script>